import { useMemo } from "react";
import { RouteLink } from "../globalTypes";
import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { getRoutes } from "./routes.api";
import { getLink } from "../components/functions/getLink";
import AppRoutes from "./AppRoutes";

const AppRoutesWrapper = () => {
  const { data } = useQuery<AxiosResponse<RouteLink[]>, Error>(
    ["query-routes"],
    async () => await getRoutes(),
  );

  const routeArr = useMemo(() => {
    if (data?.data) {
      const newData = data?.data?.map((route) => {
        const newRoute = { ...route };
        newRoute.url = getLink(newRoute.url);
        return newRoute;
      });
      return newData;
    } else {
      return [];
    }
  }, [data]);

  if (routeArr.length === 0) {
    return null;
  }

  return <AppRoutes routeArr={routeArr} />;
};

export default AppRoutesWrapper;
