import "../../assets/scss/header.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { useNavigate } from "react-router-dom";
import LogoSvg from "../svg/LogoSvg";
import DropDownIndicator from "../svg/DropDownIndicator";
import HamburgerSvg from "../svg/HamburgerSvg";
import { HeaderLinks, IHeader, NavigationItems } from "../../globalTypes";
import { getLink } from "../functions/getLink";
import {
  darkButtonStyle,
  lightButtonStyle,
} from "../../pages/Modules/CustomStyles";
import { HomeLinks } from "../../consts/links";
interface Props {
  data: IHeader;
}

interface ILink extends NavigationItems {
  index: number;
  isOpen?: boolean;
}

const Header = ({ data }: Props) => {
  const [navigationLinks, setNavigationLinks] = useState<ILink[] | []>([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const [isLangMenuOpen, setIsLangMenuOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const langMenuRef = useRef(null);
  const navDropdownsRef = useRef(null);

  useEffect(() => {
    if (data?.navigationItems) {
      const newLinks = data?.navigationItems?.map((link, index) => {
        if (link?.columns?.length > 0) {
          return { ...link, index, isOpen: false };
        } else {
          return { ...link, index };
        }
      });
      setNavigationLinks(newLinks);
    }
  }, [data]);

  const resetedNavigationLinks = [...navigationLinks].map((l) => ({
    ...l,
    isOpen: false,
  }));

  useOnClickOutside(langMenuRef, () => setIsLangMenuOpen(false));
  useOnClickOutside(navDropdownsRef, () =>
    setNavigationLinks(resetedNavigationLinks),
  );

  const selectedLanguageCode = useMemo(
    () => data.languageLinks.find((lang) => lang.isCurrent),
    [data],
  );

  const homePageLink =
    HomeLinks[selectedLanguageCode?.culture.split("-")[0].toUpperCase() ?? ""];

  return (
    <>
      <div
        id="header"
        className="fixed left-0 right-0 top-0 z-30 flex h-[80px] w-full justify-between border-b border-primary-stroke bg-white py-1 2lg:justify-center"
      >
        <div className="mx-auto flex w-full justify-between px-[20px] font-roboto text-blue-dark 2lg:justify-center xl:px-0">
          <div
            className="mr-6 flex h-full cursor-pointer items-center lg:mr-8 2xl:mr-12"
            onClick={() => navigate(homePageLink)}
          >
            <LogoSvg />
          </div>

          <div
            ref={navDropdownsRef}
            className="items-menu hidden items-center font-semibold 2lg:flex"
          >
            {navigationLinks?.map((item: ILink, index) => (
              <div className="w-full text-xs xl:text-base" key={index}>
                <div
                  className={
                    item?.link?.isActive
                      ? "relative flex h-full items-center pr-6 text-blue hover:cursor-pointer 2xl:pr-8"
                      : "relative flex h-full items-center pr-6 text-blue-dark hover:cursor-pointer hover:text-blue-hover 2xl:pr-8"
                  }
                  onClick={() => {
                    if (item.columns?.length > 0) {
                      const oldLinks = resetedNavigationLinks;
                      const newLink = { ...item, isOpen: !item.isOpen };
                      oldLinks.splice(index, 1, newLink);
                      setNavigationLinks(oldLinks);
                    } else {
                      const linkURL = getLink(item?.link?.url);
                      navigate(linkURL);
                    }
                  }}
                >
                  <span className="link flex h-4 items-center py-6">
                    {item.link?.label}
                    {item.columns?.length > 0 && (
                      <DropDownIndicator isOpen={!!item.isOpen} />
                    )}
                  </span>
                </div>
                {item?.isOpen && (
                  <div className="dropdown-content pt-[16.5px]">
                    <div className="dropdown-items flex justify-evenly border border-primary-stroke bg-white">
                      {item?.columns?.map((sublink, index) => (
                        <div key={index} className="px-4 py-2">
                          {sublink?.heading && (
                            <div className="text-md py-1 font-bold">
                              {sublink?.heading}
                            </div>
                          )}
                          {sublink?.links?.map((l, index) => (
                            <div
                              className={
                                l?.isActive
                                  ? "text-md py-1 font-normal text-blue hover:cursor-pointer"
                                  : "text-md py-1 font-normal hover:cursor-pointer hover:text-blue-hover"
                              }
                              onClick={() => {
                                const linkURL = getLink(l?.url);
                                navigate(linkURL);
                              }}
                              key={index}
                            >
                              {l.label}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* btns desktop */}
          <div className="hidden items-center justify-between text-xs 2lg:flex xl:text-base">
            {data.links.map((l: HeaderLinks) => (
              <button
                key={l.link.label}
                onClick={() => {
                  const linkURL = getLink(l?.link?.url);
                  navigate(linkURL);
                }}
                className={
                  l.color === "dark"
                    ? `${darkButtonStyle} ml-[12px] mr-2.5 px-[30px] py-2.5`
                    : `${lightButtonStyle} px-[30px] py-2.5`
                }
              >
                {l.link.label}
              </button>
            ))}
          </div>

          <div
            ref={langMenuRef}
            className="link dropdown-div relative hidden h-full items-center text-xs hover:cursor-pointer 2lg:flex xl:text-base"
            onClick={() => {
              setIsLangMenuOpen((prev) => !prev);
            }}
          >
            <span className="link ml-[20px] flex h-full items-center py-6 font-semibold hover:text-blue">
              {selectedLanguageCode?.code}
              <DropDownIndicator isOpen={isLangMenuOpen} />
            </span>
            {isLangMenuOpen && (
              <div className="dropdown-items w-min-[160px] absolute -left-9 top-[4.75rem] flex flex-col justify-evenly border border-t-0 border-primary-stroke bg-white px-4 py-1 2xl:-left-0">
                {data?.languageLinks.map((link) => (
                  <a
                    key={link.code}
                    className={
                      link?.isCurrent
                        ? "text-md py-1 font-normal text-blue hover:cursor-pointer"
                        : "text-md py-1 font-normal hover:cursor-pointer hover:text-blue"
                    }
                    onClick={() => {
                      const linkURL = getLink(link?.url);
                      navigate(linkURL);
                    }}
                  >
                    {link.name}
                  </a>
                ))}
              </div>
            )}
          </div>

          <div
            id="hamburger"
            className="flex items-center justify-between 2lg:hidden"
          >
            <button
              className="mx-2 2lg:hidden"
              onClick={() => {
                setIsMobileMenuOpen((current) => !current);
              }}
            >
              <HamburgerSvg />
            </button>
          </div>
        </div>
      </div>

      {/* mobile navigation */}
      {isMobileMenuOpen && (
        <div
          ref={navDropdownsRef}
          id="mobileNav"
          className="items-menu fixed left-0 top-[80px] z-50 flex w-full flex-col items-center overflow-y-auto  bg-blue-light font-semibold shadow-md 2lg:hidden"
        >
          {navigationLinks?.map((item: ILink, index) => (
            <div className="w-full" key={index}>
              <div
                className="flex w-full items-center justify-between px-12 py-4 hover:cursor-pointer hover:bg-gray-light"
                onClick={() => {
                  if (item.columns?.length > 0) {
                    const oldLinks = resetedNavigationLinks;
                    const newLink = { ...item, isOpen: !item.isOpen };
                    oldLinks.splice(index, 1, newLink);
                    setNavigationLinks(oldLinks);
                  } else {
                    const linkURL = getLink(item?.link?.url);
                    navigate(linkURL);
                    setIsMobileMenuOpen(false);
                  }
                }}
              >
                {item.link?.label}
                {item.columns?.length > 0 && (
                  <DropDownIndicator isOpen={!!item.isOpen} />
                )}
              </div>
              {item?.isOpen && (
                <div>
                  {item?.columns?.map((sublink, index) => (
                    <div key={index} className="px-16 py-2">
                      <div className="text-md py-1 font-bold">
                        {sublink?.heading}
                      </div>
                      {sublink?.links?.map((l, index) => (
                        <div
                          className="text-md py-1 font-normal hover:cursor-pointer hover:bg-gray-light"
                          onClick={() => {
                            const linkURL = getLink(l?.url);
                            navigate(linkURL);
                            setIsMobileMenuOpen(false);
                          }}
                          key={index}
                        >
                          {l.label}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}

          <div ref={langMenuRef} className="w-full text-base">
            <div
              onClick={() => setIsLangMenuOpen((current) => !current)}
              className="flex w-full items-center justify-between px-12 py-4 hover:cursor-pointer hover:bg-gray-light"
            >
              {selectedLanguageCode?.code}
              <div>
                <DropDownIndicator isOpen={isLangMenuOpen} />
              </div>
            </div>
            {isLangMenuOpen && (
              <div className="px-16 py-2 text-base">
                {data?.languageLinks.map((link) => (
                  <div
                    key={link.name}
                    className="py-1 text-base font-normal hover:cursor-pointer hover:bg-gray-light"
                    onClick={() => {
                      const linkURL = getLink(link.url);
                      navigate(linkURL);
                      setIsLangMenuOpen(false);
                      setIsMobileMenuOpen(false);
                    }}
                  >
                    {link.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
