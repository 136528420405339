const DropDownIndicator = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <svg
      className={`ml-2 h-[10px] w-[12px] text-inherit ${isOpen ? "rotate-180" : ""}`}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 8"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
      />
    </svg>
  );
};

export default DropDownIndicator;
